import executeRequest, { RequestOutput } from 'app/api'

enum APIEndpoint {
  GetNutritionalValues = 'api/nutritionals',
  GetNutritionalValueById = 'api/nutritionals/getById',
  CreateNutritionalValue = 'api/nutritionals/create',
  UpdateNutritionalValue = 'api/nutritionals/update',
  DeleteNutritionalValue = 'api/nutritionals/delete',
  GetProductNutritionalValues = 'api/products/getNutritionals',
  AddProductNutritionalValue = 'api/products/addNutritional',
  RemoveProductNutritionalValue = 'api/products/removeNutritional',
  UpdateProductNutritionalValue = 'api/products/updateNutritional',
}



export type GetNutritionalValuesRequestParams = {
}
export type GetNutritionalValuesResponse = {
  nutritionals: {
    id: number
    keyword: string
    itemOrder: number | null
    minVal: number | null
    maxVal: number | null
    unit: string | null
  }[]
}

enum GetNutritionalValuesResponseError {
}

export async function getNutritionalValues(output?: RequestOutput<GetNutritionalValuesResponse, GetNutritionalValuesResponseError>) {
  executeRequest({
    endpoint: APIEndpoint.GetNutritionalValues,
    output: output,
  })
}



export type GetNutritionalValueRequestParams = {
  id: number
}
export type GetNutritionalValueResponse = {
  nutritional: {
    id: number
    keyword: string
    itemOrder: number | null
    minVal: number | null
    maxVal: number | null
    unit: string | null
  }
}

enum GetNutritionalValueResponseError {
}

export async function getNutritionalValue(params: GetNutritionalValueRequestParams, output?: RequestOutput<GetNutritionalValueResponse, GetNutritionalValueResponseError>) {
  executeRequest({
    endpoint: APIEndpoint.GetNutritionalValueById,
    params: params,
    output: output,
  })
}



export type CreateNutritionalValueRequestParams = {
}

type CreateNutritionalValueResponse = {
  nutritional: {
    id: number
    keyword: string
    itemOrder: number | null
    minVal: number | null
    maxVal: number | null
    unit: string | null
  }
}

enum CreateNutritionalValueReponseError {
}

export async function createNutritionalValue(params: CreateNutritionalValueRequestParams, output?: RequestOutput<CreateNutritionalValueResponse, CreateNutritionalValueReponseError>) {
  executeRequest({
    endpoint: APIEndpoint.CreateNutritionalValue,
    params: params,
    output: output,
  })
}



export type UpdateNutritionalValueRequestParams = {
  id: number
  keyword: string
  itemOrder: number | null
  minVal: number | null
  maxVal: number | null
  unit: string | null
}

type UpdateNutritionalValueResponse = {
}

enum UpdateNutritionalValueReponseError {
}

export async function updateNutritionalValue(params: UpdateNutritionalValueRequestParams, output?: RequestOutput<UpdateNutritionalValueResponse, UpdateNutritionalValueReponseError>) {
  executeRequest({
    endpoint: APIEndpoint.UpdateNutritionalValue,
    params: params,
    output: output,
  })
}



export type DeleteNutritionalValueRequestParams = {
  id: number
}

type DeleteNutritionalValueResponse = {
}

enum DeleteNutritionalValueReponseError {
}

export async function deleteNutritionalValue(params: DeleteNutritionalValueRequestParams, output?: RequestOutput<DeleteNutritionalValueResponse, DeleteNutritionalValueReponseError>) {
  executeRequest({
    endpoint: APIEndpoint.DeleteNutritionalValue,
    params: params,
    output: output,
  })
}



export type GetProductNutritionalValuesRequestParams = {
  productId: number
}

export type GetProductNutritionalValuesResponse = {
  productNutritional: {
    id: number
    nutritionalId: number
    keyword: string
    itemOrder: number | null
    minVal: number | null
    maxVal: number | null
    unit: string | null
    value: number
  }[]
}

enum GetProductNutritionalValuesReponseError {
}

export async function getProductNutritionalValues(params: GetProductNutritionalValuesRequestParams, output?: RequestOutput<GetProductNutritionalValuesResponse, GetProductNutritionalValuesReponseError>) {
  executeRequest({
    endpoint: APIEndpoint.GetProductNutritionalValues,
    params: params,
    output: output,
  })
}




export type AddProductNutritionalValueRequestParams = {
  productId: number
  nutritionalId: number
  value: number
}

type AddProductNutritionalValueResponse = {
  productNutritional: {
    productId: number
    nutritionalId: number
    value: number
  }
}

enum AddProductNutritionalValueReponseError {
}

export async function addProductNutritionalValue(params: AddProductNutritionalValueRequestParams, output?: RequestOutput<AddProductNutritionalValueResponse, AddProductNutritionalValueReponseError>) {
  executeRequest({
    endpoint: APIEndpoint.AddProductNutritionalValue,
    params: params,
    output: output,
  })
}


//Retrieve the authentication token.
//Used when logging in and authorizing the client to make future API calls.

export type RemoveProductNutritionalValueRequestParams = {
  productId: number
  nutritionalId: number
}

type RemoveProductNutritionalValueResponse = {
}

enum RemoveProductNutritionalValueReponseError {
}

export async function removeProductNutritionalValue(params: RemoveProductNutritionalValueRequestParams, output?: RequestOutput<RemoveProductNutritionalValueResponse, RemoveProductNutritionalValueReponseError>) {
  executeRequest({
    endpoint: APIEndpoint.RemoveProductNutritionalValue,
    params: params,
    output: output,
  })
}



export type UpdateProductNutritionalValueRequestParams = {
  id: number
  value: number
}

type UpdateProductNutritionalValueResponse = {
}

enum UpdateProductNutritionalValueReponseError {
}

export async function updateProductNutritionalValue(params: UpdateProductNutritionalValueRequestParams, output?: RequestOutput<UpdateProductNutritionalValueResponse, UpdateProductNutritionalValueReponseError>) {
  executeRequest({
    endpoint: APIEndpoint.UpdateProductNutritionalValue,
    params: params,
    output: output,
  })
}