import { useState, ReactNode } from 'react'
import clsx from 'clsx';
import { makeStyles, Typography, Menu, IconButton } from '@material-ui/core'
import { FontWeight, Color } from 'theme/style'
import { useRouter } from 'app/utils'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import MoreVertIcon from '@material-ui/icons/MoreVert';

const useStyles = makeStyles((theme) => ({
  header: {
    width: 'calc(100% - 280px)',
    backgroundColor: Color.White,
    height: '60px',
    flex: '0 0 60px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    padding: theme.spacing(0, 2),
    alignItems: 'center',
    boxShadow: '0 8px 12px 0 rgba(0,0,0,0.08)',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  leftContent: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    flex: 1,
    [theme.breakpoints.down('sm')]: {
      marginLeft: '40px',
    },
  },
  rightContent: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    flex: '0 0 auto'
  },
  backArrowContainer: {
    cursor: 'pointer',
    width: '40px',
    height: '40px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginRight: theme.spacing(1),
    borderRadius: '20px',
    transition: 'all 0.2s',
    '&:hover': {
      backgroundColor: Color.Background,
    }
  },
  backArrow: {
    height: '24px',
    width: '24px',
    color: Color.TextPrimary,
  },
  titleContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  title: {
  },
  subtitle: {
    fontWeight: FontWeight.Regular,
    color: theme.palette.text.primary
  },
  actions: {
    display: 'flex',
    justifyContent: 'flex-end',
    '&>*': {
      marginLeft: theme.spacing(1)
    }
  }
}))


type PageHeaderProps = {
  className?: string
  title: string
  actions?: ReactNode
  secondaryActions?: ReactNode[]
  previousRoute?: string
}
const PageHeader = ({ className, title, actions, secondaryActions, previousRoute }: PageHeaderProps) => {
  const classes = useStyles()
  const router = useRouter()



  //ACTIONS

  const [anchorSecondaryActionsEl, setAnchorSecondaryActionsEl] = useState<null | HTMLElement>(null)
  const secondaryActionsAreOpen = Boolean(anchorSecondaryActionsEl)

  const openSecondaryActions = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorSecondaryActionsEl(event.currentTarget)
  }

  const closeSecondaryActions = () => {
    setAnchorSecondaryActionsEl(null)
  }



  //RENDER

  return (
    <div className={clsx(className, classes.header)} >
      <div className={classes.leftContent}>
        {previousRoute != null &&
          <div className={classes.backArrowContainer} onClick={() => router.history.push(previousRoute)}>
            <ArrowBackIcon className={classes.backArrow} />
          </div>
        }
        <div className={classes.titleContainer}>
          <Typography className={classes.title} component="h1" variant="h3">
            {title}
          </Typography>
        </div>
      </div>

      {actions != null &&
        <div className={classes.rightContent}>
          {actions}
          {(secondaryActions != null && secondaryActions?.length !== 0) &&
            <>
              <IconButton onClick={openSecondaryActions}><MoreVertIcon /></IconButton>
              <Menu anchorEl={anchorSecondaryActionsEl} open={secondaryActionsAreOpen} onClick={closeSecondaryActions} onClose={closeSecondaryActions}>
                {secondaryActions?.map(e => e)}
              </Menu>
            </>
          }
        </div>
      }
    </div>
  )
}

export default PageHeader
