import { CardActions, TablePagination, makeStyles } from '@material-ui/core'
import { TableResultsOption } from 'components'
import { useLocalization } from 'components/methods'
import React, { useState, useEffect } from 'react'

const useStyles = makeStyles((theme) => ({
  actions: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    height: '60px',
    minHeight: '40px'
  },
}))

interface CardListingFooterProps {
  page: number,
  rowsPerPage: number
  count: number
  resultsOptions?: number[]
  handleChangePageCallback?: (page: number) => void
  handleChangeRowsPerPageCallback?: (rows: number) => void
}

const CardListingFooter = ({ ...props }: CardListingFooterProps) => {
  const classes = useStyles()
  const { t } = useLocalization()

  const [lastPage, setLastPage] = useState(1)

  let resultsOptions = props.resultsOptions ?? [
    TableResultsOption._10,
    TableResultsOption._25,
    TableResultsOption._50,
    TableResultsOption._100,
    TableResultsOption._250,
  ]

  useEffect(() => {
    let last = Math.ceil(props.count / props.rowsPerPage)
    if (last <= 0) last = 1
    setLastPage(last)
  }, [props.rowsPerPage, props.count])

  const handleChangePage = (page: number) => {
    if (props.handleChangePageCallback != null) props.handleChangePageCallback(page)
  }

  const handleChangeRowsPerPage = (rows: number) => {
    if (props.handleChangeRowsPerPageCallback != null) props.handleChangeRowsPerPageCallback(rows)
    if (props.page + 1 > lastPage) handleChangePage(lastPage)
  }

  return (
    <CardActions className={classes.actions}>
      {/* <Pagination color="primary"
        count={Math.ceil(count / rowsPerPage)}
        page={page + 1}
        onChange={(e, p) => handleChangePage(p)}
      ></Pagination> */}

      <TablePagination component="div" count={props.count} page={props.page} rowsPerPage={props.rowsPerPage}
        onPageChange={(e, p) => handleChangePage(p)} onChangeRowsPerPage={e => handleChangeRowsPerPage(parseInt(e.target.value))}
        rowsPerPageOptions={resultsOptions} labelRowsPerPage={t('component.CardListing.Footer.results.results-number-picker-label')}
        labelDisplayedRows={({ from, to, count }) => {
          // return t('component.CardListing.Footer.results.minified', {
          //   tags: { COUNT: count },
          //   quantity: { ITEMS: count }
          // })
          return t('component.CardListing.Footer.results.overview', {
            tags: { FROM: from, TO: to, COUNT: count },
            quantity: { ITEMS: count }
          })
          // return t('component.CardListing.Footer.results.simple', {
          //   tags: { COUNT: count, CURRENTPAGE: props.page + 1, TOTALPAGES: lastPage },
          //   quantity: { ITEMS: count }
          // })
          // return t('component.CardListing.Footer.results.extended', {
          //   tags: { FROM: from, TO: to, COUNT: count, CURRENTPAGE: props.page + 1, TOTALPAGES: lastPage },
          //   quantity: { ITEMS: count }
          // })
        }} />
    </CardActions>
  )
}

export default CardListingFooter
