import React from 'react'
import clsx from 'clsx'
import { Button, ButtonProps, makeStyles } from '@material-ui/core'
import { FieldsListErrors } from 'components'
import { isValid } from 'components/methods'
import { Color } from 'theme/style'
import { darken, fade } from '@material-ui/core/styles'
import AddIcon from '@material-ui/icons/Add'

const useStyles = makeStyles((theme) => ({
  button: {
    color: Color.Button + ' !important',
    '&:hover': {
      backgroundColor: fade(Color.Button, 0.1) + ' !important',
    }
  },
  buttonProminent: {
    backgroundColor: Color.Button + ' !important',
    color: Color.White + ' !important',
    '&:hover': {
      backgroundColor: darken(Color.Button, 0.1) + ' !important',
    }
  },

  icon: {
    width: '18px',
    height: '18px',
    marginRight: '4px',
  },

  buttonDisabled: {
    opacity: 0.4
  },

  buttonPrimary: {
    color: Color.Primary + ' !important',
    '&:hover': {
      backgroundColor: fade(Color.Primary, 0.1) + ' !important',
    }
  },
  buttonPrimaryProminent: {
    backgroundColor: Color.Primary + ' !important',
    color: Color.White + '!important',
    '&:hover': {
      backgroundColor: darken(Color.Primary, 0.2) + ' !important',
    }
  },

  buttonSecondary: {
    color: Color.Secondary + ' !important',
    '&:hover': {
      backgroundColor: fade(Color.Secondary, 0.1) + ' !important',
    }
  },
  buttonSecondaryProminent: {
    backgroundColor: Color.Secondary + ' !important',
    color: Color.White + '!important',
    '&:hover': {
      backgroundColor: darken(Color.Secondary, 0.2) + ' !important',
    }
  },

  buttonConfirm: {
    color: Color.Success + ' !important',
    '&:hover': {
      backgroundColor: fade(Color.Success, 0.1) + ' !important',
    }
  },
  buttonConfirmProminent: {
    backgroundColor: Color.Success + ' !important',
    color: Color.White + '!important',
    '&:hover': {
      backgroundColor: darken(Color.Success, 0.2) + ' !important',
    }
  },

  buttonDelete: {
    color: Color.Error + ' !important',
    '&:hover': {
      backgroundColor: fade(Color.Error, 0.1) + ' !important',
    }
  },
  buttonDeleteProminent: {
    backgroundColor: Color.Error + ' !important',
    color: Color.White + '!important',
    '&:hover': {
      backgroundColor: darken(Color.Error, 0.2) + ' !important',
    }
  },
}))

export type CustomButtonCategory = 'default' | 'primary' | 'secondary' | 'confirm' | 'delete'

export interface CustomButtonProps extends ButtonProps {
  className?: string
  category?: CustomButtonCategory
  prominent?: boolean
  mandatoryFields?: string[]
  fieldsConditions?: FieldsListErrors
  iconType?: 'add' | 'delete'
}

const CustomButton = ({ ...props }: CustomButtonProps) => {
  const classes = useStyles()

  const [disabled, setDisabled] = React.useState(props.disabled)

  React.useEffect(() => {
    setDisabled(props.disabled)
  }, [props.disabled])

  React.useEffect(() => {
    if (props.fieldsConditions == null) return
    if (props.mandatoryFields == null) return
    const valid = isValid(props.fieldsConditions, props.mandatoryFields)
    setDisabled(!valid)
  }, [props.fieldsConditions, props.mandatoryFields])

  let buttonClass = props.prominent ? classes.buttonProminent : classes.button
  if (props.category === 'primary') buttonClass = props.prominent ? classes.buttonPrimaryProminent : classes.buttonPrimary
  if (props.category === 'secondary') buttonClass = props.prominent ? classes.buttonSecondaryProminent : classes.buttonSecondary
  if (props.category === 'confirm') buttonClass = props.prominent ? classes.buttonConfirmProminent : classes.buttonConfirm
  if (props.category === 'delete') buttonClass = props.prominent ? classes.buttonDeleteProminent : classes.buttonDelete

  let icon: React.ReactNode = <></>
  if (props.iconType === 'add') icon = <AddIcon className={classes.icon} />

  return (
    <>
      <Button {...props} className={disabled === true ? clsx(buttonClass, classes.buttonDisabled, props.className) : clsx(buttonClass, props.className)} disabled={disabled}>
        {icon}{props.children}
      </Button>
    </>
  )
}

export default CustomButton
