import { Card, makeStyles } from '@material-ui/core'
import {
  updatePageImage, UpdatePageImageRequestParams
} from 'app/api'
import { MediaLibraryFile } from 'app/entities/types'
import { toast } from 'app/utils'
import { MediaLibraryFolderValue } from 'app/values'
import clsx from 'clsx'
import { CardContent, CardField, CardHeader, MediaLibraryFilePicker, PageEntityDetailsRefreshableProps } from 'components'
import { useLocalization } from 'components/methods'
import clone from 'lodash/cloneDeep'
import React, { useEffect, useState } from 'react'

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100%',
  },
  imageContainer: {
    display: 'flex',
    justifyContent: 'center',
  },
  image: {
    width: '100%',
    height: '300px',
    border: 'none',
    backgroundSize: 'contain',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat'
  },
}))

interface CardCoverImageProps extends PageEntityDetailsRefreshableProps {
  pageId: number,
  image?: MediaLibraryFile | null,
  title?: string,
  mediaLibraryFolder?: MediaLibraryFolderValue,
  handleImageSelectedCallback?: (item: MediaLibraryFile | null) => void,
  handleImageConfirmedCallback?: (item: MediaLibraryFile | null) => void
}
const CardCoverImage = ({ ...props }: CardCoverImageProps) => {
  const classes = useStyles()
  const { t } = useLocalization()

  const [isLoading, setIsLoading] = useState(true)

  const [selectedImage, setSelectedImage] = useState<MediaLibraryFile | null>(null)
  const [imagePickerIsOpen, setImagePickerIsOpen] = useState(false)

  useEffect(() => {
    const img = clone(props.image)
    setSelectedImage(img ?? null)
  }, [])

  useEffect(() => {
    const initialized = props.pageId != null
    setIsLoading(!initialized)
  }, [props.pageId])

  function openImagePicker() {
    setImagePickerIsOpen(true)
  }

  function closeImagePicker() {
    setImagePickerIsOpen(false)
  }

  function imagePickerSelected(selectedItem: MediaLibraryFile | null) {
    if (props.handleImageSelectedCallback != null) props.handleImageSelectedCallback(selectedItem)
  }

  function imagePickerConfirm(selectedItem: MediaLibraryFile | null) {
    setSelectedImage(selectedItem)
    if (props.handleImageConfirmedCallback != null) props.handleImageConfirmedCallback(selectedItem)

    updateObject(selectedItem)
  }

  function removeImage() {
    setSelectedImage(null)
    updateObject(null)
  }


  function updateObject(selectedItem: MediaLibraryFile | null) {
    const encode = (): UpdatePageImageRequestParams => {
      return {
        id: props.pageId,
        mediaFileId: selectedItem?.id ?? null
      }
    }

    updatePageImage(encode(), {
      response(data) {
        toast.success(t('alert.page-data.cover-image.update'))
        if (props.refreshObject != null) props.refreshObject()
      },
      error(error, message) {
        toast.error(message)
      }
    })
  }

  return (
    <>
      <Card className={clsx(classes.root)}>
        <CardHeader title={props.title ?? t('tab.page-data.cover-image.title')} />
        <CardContent isLoading={isLoading}>
          <CardField type={'image'} name={'cover-image'}
            label={t('tab.page-data.cover-image.image.label')}
            value={selectedImage?.path ?? ''}
            onClick={openImagePicker} onRemove={removeImage}
          />
        </CardContent>
      </Card>

      <MediaLibraryFilePicker selectedItem={selectedImage} mediaLibraryFolder={props.mediaLibraryFolder}
        enableUpload={true} open={imagePickerIsOpen} onClose={closeImagePicker}
        onSelectCallback={imagePickerSelected} onConfirmCallback={imagePickerConfirm}
      />
    </>
  )
}

export default CardCoverImage