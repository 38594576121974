import React, { useEffect, useState, ReactNode } from 'react'
import { makeStyles } from '@material-ui/core'
import { useSelector, useDispatch, useStore, connect, ConnectedProps } from 'react-redux'
import { RootState } from 'app/session/store'
import { languageIs } from 'app/entities/methods'
import { config } from 'app/config'
import { login, updateUserPermissions, updateLanguages, updateMediaLibraryFolders, updatePageTypes, updateFieldGroupTypes, updateFieldTypes, updateSelectedLanguage } from 'app/session/actions'
import {
  getAllMediaLibraryFolders, GetAllMediaLibraryFoldersResponse,
  getCurrentUser, getLanguages, getPageTypes, getFieldGroupTypes, getFieldTypes,
  getUserRole, GetUserRoleRequestParams, GetUserRoleResponse,
} from 'app/api'
import { User, UserRole, Language, PageType, FieldGroupType, FieldType, MediaLibraryFolder } from 'app/entities/types'
import { AuthGuard, AuthGuardParams, Spinner } from 'components'
import { tokenIsAvailable } from 'app/utils';
import { userCan } from 'app/entities/methods';
import { UserPermissionValue } from 'app/values'

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100%',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center'
  },
}))


//Redux store mapping setup.
type MappedStateProps = {
  userPermissions?: UserRole
}
const mapState = (state: RootState): MappedStateProps => {
  return {
    userPermissions: state.session.user.role
  }
}
const connector = connect(mapState)


type PropsFromRedux = ConnectedProps<typeof connector>
type SetupProps = PropsFromRedux & {
  children?: ReactNode,
}

const Setup = (props: SetupProps) => {
  const [isInitialized, setIsInitialized] = useState(false)
  const [userIsInvalid, setUserIsInvalid] = useState(false)

  const classes = useStyles()
  const dispatch = useDispatch()
  const store = useStore()
  const session = useSelector((state: RootState) => state.session)



  //GUARDS.

  const [guards, setGuards] = useState<AuthGuardParams[]>([])
  function updateGuards() {
    const guards = [
      {
        check: tokenIsAvailable() === true,
        onDeny: '/auth/login'
      },
      {
        check: userIsInvalid === false,
        onDeny: '/auth/login'
      },
    ]
    setGuards(guards)
  }

  const [innerGuards, setInnerGuards] = useState<AuthGuardParams[]>([])
  function updateInnerGuards() {
    const guards = [
      {
        check: userCan(session.user, UserPermissionValue.BackofficeAccess) === true,
        onDeny: '/error/403'
      },
    ]
    setInnerGuards(guards)
  }



  //INIT.

  useEffect(() => {
    checkInitialization()
    updateGuards()

    fetchUser()
    fetchLanguages()
    fetchMediaLibraryFolders()
    fetchPageTypes()
    fetchFieldGroupTypes()
    fetchFieldTypes()

    function fetchUser() {
      if (session.user.isLogged === true) {
        console.log('User already logged in.')
        return
      }

      getCurrentUser({
        response(data) {
          const user: User = data.user
          dispatch(login(user))
          fetchUserPermissions(user)
        },
        error(error) {
          setUserIsInvalid(true)
        }
      })
    }

    function fetchUserPermissions(user: User) {
      if (user.role == null) return
      const encode = (): GetUserRoleRequestParams => {
        return {
          id: user.role!.id
        }
      }

      const decode = (data: GetUserRoleResponse): UserRole => {
        return data.role
      }

      getUserRole(encode(), {
        response(data) {
          const userRole = decode(data)
          dispatch(updateUserPermissions(userRole))
        },
      })
    }

    function fetchLanguages() {
      if (session.app.languages.length !== 0) {
        console.log('Languages already downloaded.')
        return
      }

      getLanguages({
        response(data) {
          const languages: Language[] = data.languages
          dispatch(updateLanguages(languages))

          const selectedLanguage = languages.find(l => languageIs(l, config.app.defaultLanguageCode))
          dispatch(updateSelectedLanguage(selectedLanguage!))
        }
      })
    }

    function fetchMediaLibraryFolders() {
      const decode = (data: GetAllMediaLibraryFoldersResponse): MediaLibraryFolder[] => {
        let object: MediaLibraryFolder[] = []

        data.folders.forEach((f, i) => {
          object.push({
            id: f.id,
            keyword: f.keyword,
            parentId: f.parentId,
            isIndexed: f.isIndexed
          })
        })

        return object
      }

      getAllMediaLibraryFolders({
        response(data) {
          const values = decode(data)
          dispatch(updateMediaLibraryFolders(values))
        },
      })
    }

    function fetchPageTypes() {
      if (session.app.pageTypes.length !== 0) {
        console.log('Page types already downloaded.')
        return
      }

      getPageTypes({
        response(data) {
          const pageTypes: PageType[] = data.pageTypes
          dispatch(updatePageTypes(pageTypes))
        }
      })
    }

    function fetchFieldGroupTypes() {
      if (session.app.fieldGroupTypes.length !== 0) {
        console.log('Field Group types already downloaded.')
        return
      }

      getFieldGroupTypes({
        response(data) {
          const fieldGroupTypes: FieldGroupType[] = data.fieldGroupTypes
          dispatch(updateFieldGroupTypes(fieldGroupTypes))
        }
      })
    }

    function fetchFieldTypes() {
      if (session.app.fieldTypes.length !== 0) {
        console.log('Field types already downloaded.')
        return
      }

      getFieldTypes({
        response(data) {
          const fieldTypes: FieldType[] = data.fieldTypes
          dispatch(updateFieldTypes(fieldTypes))
        }
      })
    }
  }, [])

  const checkInitialization = () => {
    //Check if the app should be considered initialized.
    const isInitialized = session.user.isLogged === true
      && session.user.role?.permissions != null
      && session.app.languages.length > 0
      // && session.app.currencies.length > 0
      && session.selectedLanguage != null
      && session.app.mediaLibraryFolders.length > 0
      && session.app.pageTypes.length > 0
      && session.app.fieldGroupTypes.length > 0
      && session.app.fieldTypes.length > 0
    setIsInitialized(isInitialized)
  }



  //UPDATE LISTENERS.

  useEffect(() => store.subscribe(() => {
    checkInitialization()
  }), [])

  useEffect(() => {
    updateGuards()
  }, [userIsInvalid])

  useEffect(() => {
    updateInnerGuards()
  }, [isInitialized, props.userPermissions])



  //RENDER.

  return (
    <AuthGuard guards={guards}>
      {isInitialized === false && (
        <div className={classes.root}>
          <Spinner />
        </div>
      )}
      {isInitialized === true && (
        <AuthGuard guards={innerGuards}>
          {props.children}
        </AuthGuard>
      )}
    </AuthGuard>
  )
}

export default connector(Setup)
