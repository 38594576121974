import executeRequest, { RequestOutput } from 'app/api'
import { APIPaginatable, APIPaginatableResponse, APISortable } from 'app/api/types'

enum APIEndpoint {
  GetAllBrands = 'api/brands',
  GetBrandById = 'api/brands/getById',
  CreateBrand = 'api/brands/create',
  UpdateBrand = 'api/brands/update',
  DeleteBrand = 'api/brands/delete',
  UpdateBrandStatus = 'api/brands/updatePublished',
  GetBrandTranslation = 'api/brands/getTranslation',
  UpdateBrandTranslation = 'api/brands/updateTranslation',
  UpdateBrandLogo = 'api/brands/updateLogo',
  UpdateBrandFlyer = 'api/brands/updateFlyer',
}


//Retrieves the languages in the CMS.
//Mainly used on application first loading, the response data is saved in the store for further use.

export interface GetAllBrandsRequestParams extends APIPaginatable, APISortable {
  languageId: number
  companyId?: number
}

export interface GetAllBrandsResponse extends APIPaginatableResponse {
  brands: {
    id: number
    keyword: string
    published: boolean | null
    parentId: number | null
    pageId: number
    surface: number
    translation: {
      name: string
      description: string
      content: string
    }[],
    companyId?: number
    company?: {
      id: number
      name: string
    }
  }[]
}

enum GetAllBrandsResponseError {
}

export async function getAllBrands(params: GetAllBrandsRequestParams, output?: RequestOutput<GetAllBrandsResponse, GetAllBrandsResponseError>) {
  executeRequest({
    endpoint: APIEndpoint.GetAllBrands,
    params: params,
    output: output,
  })
}



export type GetBrandRequestParams = {
  id: number
  languageId: number
}
export type GetBrandResponse = {
  brand: {
    id: number
    keyword: string
    published: boolean | null
    parentId: number | null
    pageId: number
    logo: {
      id: number
      type: string
      folder: number
      filename: string
      basepath: string
      path: string
    },
    flyer: {
      id: number
      type: string
      folder: number
      filename: string
      basepath: string
      path: string
    },
    mainImage: {
      id: number
      type: string
      folder: number
      filename: string
      basepath: string
      path: string
    },
    surface: number
    companyId: number | null
    translation: {
      name: string
      description: string
      content: string
    }[],
  }
}

enum GetBrandResponseError {
}

export async function getBrand(params: GetBrandRequestParams, output?: RequestOutput<GetBrandResponse, GetBrandResponseError>) {
  executeRequest({
    endpoint: APIEndpoint.GetBrandById,
    params: params,
    output: output,
  })
}



export type CreateBrandRequestParams = {
  parentId: number | null
}

type CreateBrandResponse = {
  brand: {
    id: number
    name: string
  }
}

enum CreateBrandReponseError {
}

export async function createBrand(params: CreateBrandRequestParams, output?: RequestOutput<CreateBrandResponse, CreateBrandReponseError>) {
  executeRequest({
    endpoint: APIEndpoint.CreateBrand,
    params: params,
    output: output,
  })
}



export type UpdateBrandRequestParams = {
  id: number
  keyword: string
  parentId: number | null
  surface: number
  companyId: number | null
}

type UpdateBrandResponse = {
}

enum UpdateBrandReponseError {
}

export async function updateBrand(params: UpdateBrandRequestParams, output?: RequestOutput<UpdateBrandResponse, UpdateBrandReponseError>) {
  executeRequest({
    endpoint: APIEndpoint.UpdateBrand,
    params: params,
    output: output,
  })
}



export type DeleteBrandRequestParams = {
  id: number
}

type DeleteBrandResponse = {
}

enum DeleteBrandReponseError {
}

export async function deleteBrand(params: DeleteBrandRequestParams, output?: RequestOutput<DeleteBrandResponse, DeleteBrandReponseError>) {
  executeRequest({
    endpoint: APIEndpoint.DeleteBrand,
    params: params,
    output: output,
  })
}



export type UpdateBrandStatusRequestParams = {
  id: number
  published: boolean,
}

type UpdateBrandStatusResponse = {
}

enum UpdateBrandStatusReponseError {
}

export async function updateBrandStatus(params: UpdateBrandStatusRequestParams, output?: RequestOutput<UpdateBrandStatusResponse, UpdateBrandStatusReponseError>) {
  executeRequest({
    endpoint: APIEndpoint.UpdateBrandStatus,
    params: params,
    output: output,
  })
}



export type GetBrandTranslationRequestParams = {
  brandId: number
  languageId: number
}

export type GetBrandTranslationResponse = {
  brandTranslation: {
    id: number,
    name: string,
    slug: string,
    description: string,
    ingredients: string,
    benefits: string,
    deepening: string,
    languageId: number
  }
}

enum GetBrandTranslationResponseError {
}

export async function getBrandTranslation(params: GetBrandTranslationRequestParams, output?: RequestOutput<GetBrandTranslationResponse, GetBrandTranslationResponseError>) {
  executeRequest({
    endpoint: APIEndpoint.GetBrandTranslation,
    params: params,
    output: output,
  })
}



export type UpdateBrandTranslationRequestParams = {
  id: number,
  name?: string | null,
  slug?: string | null,
  description?: string | null,
  ingredients?: string | null,
  benefits?: string | null,
  deepening?: string | null,
}

type UpdateBrandTranslationResponse = {
}

enum UpdateBrandTranslationReponseError {
}

export async function updateBrandTranslation(params: UpdateBrandTranslationRequestParams, output?: RequestOutput<UpdateBrandTranslationResponse, UpdateBrandTranslationReponseError>) {
  executeRequest({
    endpoint: APIEndpoint.UpdateBrandTranslation,
    params: params,
    output: output,
  })
}



export type UpdateBrandLogoRequestParams = {
  id: number
  mediaFileId: number | null
}

type UpdateBrandLogoResponse = {
}

enum UpdateBrandLogoReponseError {
}

export async function updateBrandLogo(params: UpdateBrandLogoRequestParams, output?: RequestOutput<UpdateBrandLogoResponse, UpdateBrandLogoReponseError>) {
  executeRequest({
    endpoint: APIEndpoint.UpdateBrandLogo,
    params: params,
    output: output,
  })
}



export type UpdateBrandFlyerRequestParams = {
  id: number
  mediaFileId: number | null
}

type UpdateBrandFlyerResponse = {
}

enum UpdateBrandFlyerReponseError {
}

export async function updateBrandFlyer(params: UpdateBrandFlyerRequestParams, output?: RequestOutput<UpdateBrandFlyerResponse, UpdateBrandFlyerReponseError>) {
  executeRequest({
    endpoint: APIEndpoint.UpdateBrandFlyer,
    params: params,
    output: output,
  })
}