
//Defines all the Tags available in the app.
//IMPORTANT: Must be mapped to each specific page tag keyword to work properly.
export enum TagValue {

  //Default tags.
  System = 'System',
  ThankYou = 'Thank You',
  Onboarding = 'Onboarding',
  Policy = 'Policy',
  Shop = 'Shop',

}