
import { UserPermissionValue } from 'app/values'

export type AuthParameters = {
  permission: UserPermissionValue
  availableRoutes: string[]
}

const auth: AuthParameters[] = [

  //Admin.
  {
    permission: UserPermissionValue.AdminFeatures,
    availableRoutes: [
      '/admin'
    ]
  },
  {
    permission: UserPermissionValue.DeveloperFeatures,
    availableRoutes: [
      '/dev'
    ]
  },


  //Website.
  {
    permission: UserPermissionValue.WebsiteContentManagement,
    availableRoutes: [
      '/website/pages',
      '/website/dictionary',
    ]
  },
  {
    permission: UserPermissionValue.MediaManagement,
    availableRoutes: [
      '/website/media-library',
    ]
  },


  //Articles.
  {
    permission: UserPermissionValue.ArticlesManagement,
    availableRoutes: [
      '/website/blog',
      '/website/article-categories',
    ]
  },


  //Shop.
  {
    permission: UserPermissionValue.ProductsManagement,
    availableRoutes: [
      '/shop/items',
      '/shop/product-catgories',
    ]
  },
  {
    permission: UserPermissionValue.OrdersManagement,
    availableRoutes: [
      '/shop/orders',
    ]
  },
  {
    permission: UserPermissionValue.CustomersManagement,
    availableRoutes: [
      '/shop/customers',
    ]
  },
]

export default auth