import React from 'react'
import clsx from 'clsx'
import { makeStyles } from '@material-ui/core'
import { FontWeight } from 'theme/style/typography'
import { config } from 'app/config'

const useStyles = makeStyles((theme) => ({
  root: {
    ...theme.typography.body2,
    height: 'auto',
    display: 'flex',
    textAlign: 'right',
    fontSize: '1.1rem',
    padding: theme.spacing(0.5),
    justifyContent: 'flex-end',
    width: '100%',
    backgroundColor: 'black',
    color: 'white',
  },
  infoGroup: {
  },
  infoGroupTitle: {
    fontWeight: FontWeight.Bold,
    marginRight: theme.spacing(0.5)
  },
  infoClickable: {
    cursor: 'pointer',
  },
  separator: {
    margin: theme.spacing(0, 1)
  }
}))


type AppStatusBarProps = {
  className?: string
}
const AppStatusBar = ({ className }: AppStatusBarProps) => {
  const classes = useStyles()

  //Doesn't display the component in production
  // if (process.env.NODE_ENV === 'production'
  //   && process.env.REACT_APP_ENVNAME === 'production')
  //   return <></>

  const nameText = process.env.REACT_APP_NAME
  const versionText = process.env.REACT_APP_VERSION


  //Environment text
  let envText = ''
  if (process.env.NODE_ENV === 'development') {
    if (process.env.REACT_APP_ENVNAME === 'local') envText = 'development (local config)'
    else if (process.env.REACT_APP_ENVNAME === 'development') envText = 'development'
  }
  else if (process.env.NODE_ENV === 'production') {
    if (process.env.REACT_APP_ENVNAME === 'staging') envText = 'staging'
    if (process.env.REACT_APP_ENVNAME === 'production') envText = 'production'
  }


  return (
    <>
      <div className={clsx(className, classes.root)}>

        {config.app.statusBar.showName === true && (
          <>
            <div className={classes.infoGroup}>
              <span className={classes.infoGroupTitle}>{nameText}</span>
            </div>
            <AppStatusBarSeparator />
          </>
        )}

        {config.app.statusBar.showVersionNumber === true && (
          <>
            <div className={classes.infoGroup}>
              <span className={classes.infoGroupTitle}>VERSION</span>{versionText}
            </div>
            <AppStatusBarSeparator />
          </>
        )}

        {config.app.statusBar.showEnvironment === true && (
          <>
            <div className={classes.infoGroup}>
              <span className={classes.infoGroupTitle}>ENV</span>{envText}
            </div>
          </>
        )}

      </div >
    </>
  )
}

export default AppStatusBar




type AppStatusBarSeparatorProps = {
}
const AppStatusBarSeparator = ({ ...props }: AppStatusBarSeparatorProps) => {
  const classes = useStyles()

  return <span className={classes.separator}>|</span>
}