import { config } from 'app/config'

enum DefaultColor {

  //Main palette.
  Black = '#000000',
  White = '#FFFFFF',
  Background = '#f4f6f9',
  Primary = '#7a49ef',
  Secondary = '#F54C7F',
  Success = '#36C2A1',
  Warning = '#fdbe1d',
  Error = '#F7422A',
  Info = '#2196f3',
  TextPrimary = '#454867',
  TextSecondary = '#7E82A3',
  TextDisabled = '#d4dce4',
  TextHighlight = '#fcdf95',
  Notification = '#ff0000',

  //Elements.
  Sidebar = '#04022d',
  SidebarTitle = '#dfd6ff',
  SidebarItemText = 'rgba(255,255,255,0.5)',
  SidebarItemTextSelected = '#ffffff',
  SidebarItemHover = '#150f3a',
  SidebarItemSelected = '#1a114b',
  SidebarIcon = 'rgba(255,255,255,0.3)',
  SidebarIconSelected = '#F54C7F',
  Divider = '#eff1f4',

  Button = '#454867',

  Spinner = '#F54C7F',

  Avatar = '#d4dce4',
  AvatarText = '#454867',
  AvatarColor = '#7a49ef',
  AvatarColorText = '#ffffff',

  //Tags.
  TagErrorBackground = '#F76A6A',
  TagErrorText = '#FFFFFF',

  TagSuccessBackground = '#67D0A2',
  TagSuccessText = '#FFFFFF',

  TagWarningBackground = '#FACD59',
  TagWarningText = '#FFFFFF',

  TagAlertBackground = '#FE945B',
  TagAlertText = '#FFFFFF',

  TagSystemBackground = '#F76A6A',
  TagSystemText = '#FFFFFF',

  TagOnboardingBackground = '#9BB6BC',
  TagOnboardingText = '#ffffff',

  TagThankYouBackground = '#67D0A2',
  TagThankYouText = '#ffffff',

  TagPolicyBackground = '#9BB6BC',
  TagPolicyText = '#ffffff',

  TagShopBackground = '#3AAEEC',
  TagShopText = '#ffffff',

  //User role tags.
  TagUserRoleDevBackground = '#9BB6BC',
  TagUserRoleDevText = '#ffffff',

  TagUserRoleAdminBackground = '#3AAEEC',
  TagUserRoleAdminText = '#ffffff',
}

enum CustomColor {

  //Main palette.
  Black = '#000000',
  White = '#FFFFFF',
  Background = '#F4F6F9',
  Primary = '#003592',
  Secondary = '#289AF7',
  Success = '#08b088',
  Warning = '#fdbe1d',
  Error = '#e02813',
  Info = '#2196f3',
  TextPrimary = '#454867',
  TextSecondary = '#707282',
  TextDisabled = '#d4dce4',
  TextHighlight = '#fcdf95',
  Notification = '#ff0000',

  //Elements.
  Sidebar = '#001C4D',
  SidebarTitle = '#ffffff',
  SidebarItemText = 'rgba(255,255,255,0.7)',
  SidebarItemTextSelected = '#ffffff',
  SidebarItemHover = '#00163D',
  SidebarItemSelected = '#00163D',
  SidebarIcon = 'rgba(255,255,255,0.3)',
  SidebarIconSelected = '#289AF7',
  Divider = '#eff1f4',

  Button = '#707282',

  Spinner = '#003592',

  Avatar = '#d4dce4',
  AvatarText = '#707282',
  AvatarColor = '#003592',
  AvatarColorText = '#ffffff',

  //Tags.
  TagErrorBackground = '#F76A6A',
  TagErrorText = '#FFFFFF',

  TagSuccessBackground = '#67D0A2',
  TagSuccessText = '#FFFFFF',

  TagWarningBackground = '#FACD59',
  TagWarningText = '#FFFFFF',

  TagAlertBackground = '#FE945B',
  TagAlertText = '#FFFFFF',

  TagSystemBackground = '#F76A6A',
  TagSystemText = '#FFFFFF',

  TagOnboardingBackground = '#9BB6BC',
  TagOnboardingText = '#ffffff',

  TagThankYouBackground = '#67D0A2',
  TagThankYouText = '#ffffff',

  TagPolicyBackground = '#9BB6BC',
  TagPolicyText = '#ffffff',

  TagShopBackground = '#3AAEEC',
  TagShopText = '#ffffff',

  //User role tags.
  TagUserRoleDevBackground = '#9BB6BC',
  TagUserRoleDevText = '#ffffff',

  TagUserRoleAdminBackground = '#3AAEEC',
  TagUserRoleAdminText = '#ffffff',
}

export default config.app.defaultTheme === true ? DefaultColor : CustomColor