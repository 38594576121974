import { Dialog, DialogActions, DialogContent, Typography, makeStyles } from '@material-ui/core'
import { Spinner, Button, EmojiResults, MediaLibraryFileCard } from 'components'
import clone from 'lodash/clone'
import { Language, MediaLibraryFile } from 'app/entities/types'
import React from 'react'
import _ from 'lodash'
import { MediaLibraryFolderValue } from 'app/values'
import {
  uploadMediaLibraryFile, UploadMediaLibraryFileRequestParams,
  getMediaLibraryFolder, GetMediaLibraryFolderRequestParams, GetMediaLibraryFolderResponse
} from 'app/api';
import { toast, convertFileToBase64, getFileName } from 'app/utils';
import { useSelector } from 'react-redux'
import { RootState } from 'app/session/store'
import { useLocalization } from 'components/methods'
import { userCan, getMediaLibraryFolderFromValue, rebuildMediaLibraryFilesData } from 'app/entities/methods'
import { UserPermissionValue } from 'app/values'
import AddIcon from '@material-ui/icons/Add'

const useStyles = makeStyles((theme) => ({
  loadingContainer: {
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  dialog: {
    width: 'calc(100% - 60px)',
    maxWidth: 'calc(100% - 60px)',
    height: 'calc(100% - 60px)',
    maxHeight: 'calc(100% - 60px)',
  },
  dialogHeader: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '30px',
  },
  titleContainer: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
  },

  fileContainer: {
    display: 'flex',
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center'
  },
  uploadMessage: {
    marginRight: theme.spacing(2),
  },
  actions: {
    display: 'flex',
    justifyContent: 'flex-end'
  },
  dialogContent: {
    overflow: 'hidden',
    padding: 0,
  },
  container: {
    height: '100%',
    position: 'relative',
    width: '100%',
    '&:after': {
      width: '100%',
      height: '80px',
      display: 'block',
      content: '""',
      position: 'absolute',
      bottom: 0,
      left: 0,
      background: 'linear-gradient(rgba(255,255,255,0) 0%, rgba(255,255,255,1) 100%)',
      zIndex: 99,
      pointerEvents: 'none'
    },
    '&:before': {
      width: '100%',
      height: '80px',
      display: 'block',
      content: '""',
      position: 'absolute',
      top: 0,
      left: 0,
      background: 'linear-gradient(rgba(255,255,255,1) 0%, rgba(255,255,255,0) 100%)',
      zIndex: 99,
      pointerEvents: 'none'
    }
  },
  libraryItemsContainer: {
    height: '100%',
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    alignContent: 'flex-start',
    overflow: 'scroll',
    padding: '50px 20px'
  },
}))


interface MediaLibraryFilePickerProps {
  open: boolean
  items?: MediaLibraryFile[]
  selectedItem?: MediaLibraryFile | null
  title?: string
  description?: string
  mediaLibraryFolder?: MediaLibraryFolderValue
  enableUpload?: boolean
  onClose: () => void
  onSelectCallback: (selectedItem: MediaLibraryFile | null) => void
  onConfirmCallback: (selectedItem: MediaLibraryFile | null) => void
}

const MediaLibraryFilePicker = ({ open, items, selectedItem, title, description, mediaLibraryFolder, enableUpload, onClose, onSelectCallback, onConfirmCallback }: MediaLibraryFilePickerProps) => {
  const { t } = useLocalization()
  const classes = useStyles()
  const session = useSelector((state: RootState) => state.session)

  const [isLoading, setIsLoading] = React.useState(true)
  const [isFetching] = React.useState(false)

  const [files, setFiles] = React.useState<MediaLibraryFile[] | null>(null)
  const [selection, setSelection] = React.useState<MediaLibraryFile | null>(null)

  if (title == null) title = t('component.MediaLibraryFilePicker.title')
  if (description == null) description = t('component.MediaLibraryFilePicker.descrption')
  if (enableUpload == null) enableUpload = false



  //INITIALIZATION

  React.useEffect(() => {
    if (open === false) return

    const initialItems = clone(items)
    setFileToUpload(null)

    if (initialItems == null) {
      fetchItems()
    }
    else {
      updateItems(initialItems)
      setSelection(selectedItem ?? null)
    }
  }, [open])

  React.useEffect(() => {
    const initialized = files != null
      && isFetching === false
    setIsLoading(!initialized)
  }, [open, files, isFetching])



  //LANGUAGE

  const [selectedLanguage, /*setSelectedLanguage*/] = React.useState<Language>(session.selectedLanguage)
  // function updateLanguage(language: Language) {
  //   setSelectedLanguage(language)
  // }



  //ITEMS

  function fetchItems() {
    if (isFetching === true) return

    if (mediaLibraryFolder == null) {
      console.error("Media Library folder not specified: unable to fetch items.")
      setFiles([])
    }

    fetchItems()

    function fetchItems() {
      const encode = (): GetMediaLibraryFolderRequestParams => {
        const folder = getMediaLibraryFolderFromValue(session, mediaLibraryFolder!)
        return {
          id: folder?.id ?? 0,
          languageId: selectedLanguage.id
        }
      }

      const decode = (data: GetMediaLibraryFolderResponse): MediaLibraryFile[] => {
        let object: MediaLibraryFile[] = []
        data.folder.files?.forEach((f, i) => {
          object.push({
            id: f.id,
            name: f.filename,
            format: f.type,
            path: f.basepath + '/' + f.filename,
            translation: f.translation
          })
        })

        //Removes the dev avatar if the user is not a dev.
        if (mediaLibraryFolder === MediaLibraryFolderValue.Avatar) {
          if (userCan(session.user, UserPermissionValue.BackofficeAccess) === false) {
            object = object.filter(o => o.name.includes('avatar0') === false);
          }
        }

        return object
      }

      getMediaLibraryFolder(encode(), {
        response(data) {
          const filesList = decode(data)
          updateItems(filesList)
          setSelection(selectedItem ?? null)
        },
        error(error, message) {
          toast.error(message)
        }
      })
    }
  }

  function updateItems(files: MediaLibraryFile[]) {
    setFiles(_.reverse(rebuildMediaLibraryFilesData(files)))
  }




  //ACTIONS

  function selectItem(item: MediaLibraryFile) {
    let itemToSelect: MediaLibraryFile | null = item
    if (itemToSelect.id === selection?.id) itemToSelect = null
    setSelection(itemToSelect)
    onSelectCallback(itemToSelect)
  }

  function confirmSelection() {
    onConfirmCallback(selection)
    onClose()
  }



  //UPLOAD

  const inputFile = React.useRef<HTMLInputElement>(null)
  const [fileToUpload, setFileToUpload] = React.useState<File | null>(null)
  const [isUploading, setIsUploading] = React.useState(false)

  //Shows the browser dialog to select a file.
  function promptFileToUpload() {
    if (enableUpload === false) return
    if (inputFile == null) return
    inputFile.current?.click()
  }

  //Triggered when the file is selected from the picker.
  function selectedFileToUpload(event: any) {
    if (enableUpload === false) return

    const target = event.target as HTMLInputElement
    const files = target.files
    if (files == null) return
    const value = files[0]
    setFileToUpload(value)
  }

  //Automatically uploads the file once it has been selected.
  React.useEffect(() => {
    if (enableUpload === false) return
    if (fileToUpload == null) return
    if (isUploading === true) return

    uploadFile()
  }, [fileToUpload])

  //Uploads the file to the server.
  async function uploadFile() {
    if (enableUpload === false) return
    if (fileToUpload == null) return

    //Converts the file to base64.
    const base64File = await convertFileToBase64(fileToUpload as any)
    uploadFileToMediaLibrary(fileToUpload, base64File)

    //Perform the actual file upload.
    function uploadFileToMediaLibrary(originalFile: File, fileData: any) {
      setIsUploading(true)
      const encode = (): UploadMediaLibraryFileRequestParams => {
        const folder = getMediaLibraryFolderFromValue(session, mediaLibraryFolder!)
        return {
          filename: getFileName(originalFile.name) ?? '',
          fileData: fileData,
          folderId: folder?.id ?? 0,
        }
      }

      uploadMediaLibraryFile(encode(), {
        response(data) {
          toast.success(t('alert.mediafile.uploaded'))
          fetchItems()
          setIsUploading(false)
          setFileToUpload(null)
        },
        error(error, message) {
          toast.error(message)
          setIsUploading(false)
          setFileToUpload(null)
        }
      })
    }
  }



  //RENDER

  return (
    <Dialog open={open} onClose={onClose} classes={{ paper: classes.dialog }}>
      <div className={classes.dialogHeader}>
        <div className={classes.titleContainer}>
          <Typography component="h2" variant="h2">{title}</Typography>
          <Typography component="p" variant="body1">{description}</Typography>
        </div>

        {enableUpload === true && (
          <div className={classes.fileContainer}>
            <input type="file" ref={inputFile} style={{ visibility: 'hidden', display: 'none' }} onChange={selectedFileToUpload} />
            {fileToUpload != null && (
              <>
                {/* <Typography className={classes.uploadMessage}>Selezionato: {fileToUpload.name}</Typography> */}
                {isUploading === false && (<Button category="secondary" endIcon={<Spinner type={'small'} />} onClick={uploadFile}>{t('component.MediaLibraryFilePicker.upload-file')}</Button>)}
                {isUploading === true && (<Button category="secondary" endIcon={<Spinner type={'small'} />}>{t('component.MediaLibraryFilePicker.uploading-file')}</Button>)}
              </>
            )}
            {fileToUpload == null && (
              <Button category={'secondary'} endIcon={<AddIcon />} onClick={promptFileToUpload}>{t('component.MediaLibraryFilePicker.action.add-new')}</Button>
            )}
          </div>
        )}
      </div>

      <DialogContent className={classes.dialogContent}>
        {isLoading === true && (
          <div className={classes.loadingContainer}>
            <Spinner />
          </div>
        )}

        {(isLoading === false && files != null && files.length === 0) && (
          <div className={classes.loadingContainer}>
            <EmojiResults emojiStyle={'angry'} />
          </div>
        )}

        {(isLoading === false && files != null && files.length > 0) && (
          <div className={classes.container}>
            <div className={classes.libraryItemsContainer}>
              {files?.map((item, i) => (
                <MediaLibraryFileCard mediaFile={item}
                  selected={selection?.id === item.id}
                  onClick={() => selectItem(item)}
                />
              ))}
            </div>
          </div>
        )}
      </DialogContent>
      <DialogActions className={classes.actions}>
        <Button onClick={onClose}>{t('component.MediaLibraryFilePicker.close')}</Button>
        <Button category={'confirm'} prominent={true} onClick={confirmSelection}>{t('component.MediaLibraryFilePicker.confirm')}</Button>
      </DialogActions>
    </Dialog >
  )
}

export default MediaLibraryFilePicker
