import React from 'react'
import { makeStyles, Card, Typography } from '@material-ui/core'
import { MediaLibraryFile } from 'app/entities/types'
import { AsyncImage } from 'components'
import { Color, FontWeight } from 'theme/style'
import { mediaLibraryFileIsImage, getMediaLibraryFileName, getMediaLibraryFileMetadataStatus } from 'app/entities/methods'
import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFile'
import { useLocalization } from 'components/methods'
import clsx from 'clsx'
import WarningIcon from '@material-ui/icons/Warning'

const useStyles = makeStyles((theme) => ({
  libraryItem: {
    position: 'relative',
    width: '10%',
    height: 'auto',
    padding: theme.spacing(0.5),
    backfaceVisibility: 'hidden',
    [theme.breakpoints.down('lg')]: {
      width: '20%',
    },
    [theme.breakpoints.down('md')]: {
      width: '25%',
    },
    [theme.breakpoints.down('sm')]: {
      width: '33.3%',
    },
    [theme.breakpoints.down('xs')]: {
      width: '50%',
    },
  },
  card: {
    position: 'relative',
    transition: 'all 0.2s',
    top: '0px',
    cursor: 'pointer',
    height: 'auto',
    border: '4px solid transparent',
    padding: 0,
    '&:hover': {
      transform: 'translate(-2px, -6px)',
      boxShadow: '16px 16px 32px 0 rgba(0,0,0,0.15)',
      zIndex: 99,
    }
  },
  cardSelected: {
    borderColor: Color.Secondary,
    boxShadow: '20px 20px 40px 0 rgba(0,0,0,0.20)',
    transform: 'translate(-2px, -6px)',
    zIndex: 99,
  },
  content: {
    width: '100%',
    height: '200px',
    display: 'flex',
    flexDirection: 'column',
    position: 'relative'
  },
  image: {
    objectFit: 'cover',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    height: '100%',
    position: 'relative',
    borderTopLeftRadius: '1.8rem',
    borderTopRightRadius: '1.8rem',
  },
  filePreview: {
    objectFit: 'cover',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    height: '100%',
    position: 'relative',
    borderTopLeftRadius: '1.8rem',
    borderTopRightRadius: '1.8rem',
  },
  filePreviewFormat: {
    color: Color.Primary,
    '& svg': {
      width: '32px',
      height: '32px'
    }
  },
  info: {
    position: 'absolute',
    width: '100%',
    height: 'auto',
    bottom: '0',
    padding: theme.spacing(1),
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'flex-end',
    flexDirection: 'column',
    paddingTop: '50%',
    background: 'linear-gradient(rgba(0,0,0,0) 0%, rgba(0,0,0,0.7) 100%)',
    borderRadius: '1.6rem',
    overflow: 'hidden'
  },
  name: {
    textAlign: 'left',
    fontWeight: FontWeight.Bold,
    color: Color.White,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  secondaryInfoContainer: {
    marginTop: theme.spacing(0.3)
  },
  format: {
    textAlign: 'left',
    color: Color.White,
    fontWeight: FontWeight.Medium,
    fontSize: '1.1rem',
    height: '20px',
    display: 'none'
  },
  meta: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    textAlign: 'left',
    fontWeight: FontWeight.Medium,
    fontSize: '1.1rem',
    color: Color.White,
    '& svg': {
      color: Color.Warning
    },
    '& span': {
      marginLeft: theme.spacing(0.3)
    }
  },
  metaUnavailable: {
    display: 'none'
  },
  metaOptional: {
    display: 'none'
  },
  metaMissing: {
  },
  metaGood: {
    display: 'none'
  },
}))

interface MediaLibraryFileCardProps {
  mediaFile: MediaLibraryFile
  selected?: boolean
  onClick?: (file: MediaLibraryFile) => void
}

const MediaLibraryFileCard = ({ ...props }: MediaLibraryFileCardProps) => {
  const classes = useStyles()
  const { t } = useLocalization()

  function onClick(file: MediaLibraryFile) {
    if (props.onClick != null) props.onClick(file)
  }

  const metadataStatus = getMediaLibraryFileMetadataStatus(props.mediaFile)
  let metadataClass = classes.metaUnavailable
  if (metadataStatus === 'optional') metadataClass = classes.metaOptional
  else if (metadataStatus === 'missing') metadataClass = classes.metaMissing
  else if (metadataStatus === 'good') metadataClass = classes.metaGood

  let selectedClass = ''
  if (props.selected === true) selectedClass = classes.cardSelected

  return (
    <div className={classes.libraryItem} onClick={() => onClick(props.mediaFile)}>
      <Card className={clsx(classes.card, selectedClass)}>
        <div className={classes.content}>
          {mediaLibraryFileIsImage(props.mediaFile) === true ?
            <AsyncImage className={classes.image} src={props.mediaFile.path} alt={props.mediaFile.name} /> :
            <div className={classes.filePreview}>
              <div className={classes.filePreviewFormat}><InsertDriveFileIcon /></div>
            </div>
          }
          <div className={classes.info}>
            <Typography variant={'body1'} className={classes.name}>{getMediaLibraryFileName(props.mediaFile)}</Typography>
            <div className={classes.secondaryInfoContainer}>
              <Typography variant={'body2'} className={classes.format}>{props.mediaFile.format}</Typography>
              <Typography component={'span'} variant={'body2'} className={clsx(classes.meta, metadataClass)}>
                <WarningIcon /><span>{t('component.MediaLibraryFileCard.metadata.status.' + metadataStatus)}</span>
              </Typography>
            </div>
          </div>
        </div>
      </Card>
    </div>
  )
}

export default MediaLibraryFileCard
