
import { AuthParameters } from 'app/routes/auth/core'
import { UserPermissionValue } from 'app/values'

const auth: AuthParameters[] = [
	{
		permission: UserPermissionValue.StoresManagement,
		availableRoutes: [
			'/general/companies',
			'/general/brands',
			'/general/stores',
		]
	},
]

export default auth