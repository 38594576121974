import { Tabs, Tab, Menu, makeStyles } from '@material-ui/core';
import { Language, Currency } from 'app/entities/types';
import { PageMetatags, PageHeader, PageContent, Spinner, LanguageSelector, CurrencySelector, Button } from 'components';
import { useLocalization } from 'components/methods'
import React, { ReactNode, useEffect, useState } from 'react';
import { useSelector } from 'react-redux'
import { RootState } from 'app/session/store'
import { userHasAccessToRoute } from 'app/entities/methods';
import { Redirect, useLocation } from "react-router-dom";
import { updateSelectedLanguage, updateSelectedCurrency } from 'app/session/actions'
import { useDispatch } from 'react-redux'
import { useRouter } from 'app/utils'
import clsx from 'clsx';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
  },
  rootFullHeight: {
    height: '100%',
  },

  loading: {
    height: '100%',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },

  header: {
    position: 'fixed',
    top: 0,
    zIndex: 9999,
  },
  contentDefault: {
    position: 'relative',
    overflow: 'hidden',
    padding: theme.spacing(3),
    paddingTop: 'calc(' + theme.spacing(3) + ' + 60px)',
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    minHeight: 'calc(100vh - 24px)',
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(1),
      paddingTop: 'calc(' + theme.spacing(1) + ' + 60px)',
    },
  },
  contentFullScreen: {
    width: '100%',
    padding: 0
  },
  topBar: {
    marginBottom: theme.spacing(1),
    marginTop: '-20px',
    display: 'flex',
    justifyContent: 'space-between',
    flex: '0 0 60px',
    height: '60px',
    alignItems: 'center',
    width: '100%',
    [theme.breakpoints.down('sm')]: {
      marginTop: 0,
      flex: '0 0 40px',
      height: '40px',
      paddingLeft: theme.spacing(1)
    },
  },
  tabsContainer: {
    display: 'block',
    height: '100%',
    overflow: 'scroll',
    marginRight: theme.spacing(2),
    flex: 1,
    position: 'relative',
    // [theme.breakpoints.down('sm')]: {
    //   display: 'none'
    // },
  },
  tabsContainerMobile: {
    flex: 1,
    display: 'none',
    // [theme.breakpoints.down('sm')]: {
    //   display: 'block'
    // },
  },
  actionsContainer: {
    display: 'block',
    flex: '0 0 auto',
    // [theme.breakpoints.down('sm')]: {
    //   display: 'none'
    // },
  },
  secondaryActionsContainer: {
    marginLeft: theme.spacing(1)
  }
}))


export type PageTabProps = {
  key: string
  label?: string
}

export type PageProps = {
  isLoading?: boolean
  className?: string
  title: string
  layout?: 'fullscreen' | 'fullheight'
  parameters?: { [key: string]: string }
  previousRoute?: string
  currentRoute?: string

  headerActions?: ReactNode
  headerSecondaryActions?: ReactNode[]
  actions?: ReactNode
  secondaryActions?: ReactNode[]

  tabs?: PageTabProps[]
  selectedTab?: string
  defaultTab?: string
  updateTab?: (tab: PageTabProps) => void
  disableTabLink?: boolean

  language?: Language
  updateLanguage?: (language: Language) => void

  currency?: Currency
  updateCurrency?: (currency: Currency) => void

  children?: ReactNode
}

const Page = ({ ...props }: PageProps) => {
  const classes = useStyles()
  const { t } = useLocalization()
  const session = useSelector((state: RootState) => state.session)
  const location = useLocation()
  const dispatch = useDispatch()
  const router = useRouter()



  //LANGUAGE

  const [selectedLanguage, setSelectedLanguage] = useState<Language>(props.language ?? session.selectedLanguage)

  function updateLanguage(language: Language) {
    if (language.id === selectedLanguage.id) return
    setSelectedLanguage(language)
    if (props.updateLanguage != null) props.updateLanguage(language)
  }

  useEffect(() => {
    //Updates the selected language in session.
    dispatch(updateSelectedLanguage(selectedLanguage))
  }, [selectedLanguage])

  // useEffect(() => store.subscribe(() => {
  //   //When the session is updated triggers the callback to notify the "parent" component.
  //   const lang = _.clone(session.selectedLanguage)
  // }), [])



  //CURRENCY

  const [selectedCurrency, setSelectedCurrency] = useState<Currency>(props.currency ?? session.selectedCurrency)

  function updateCurrency(currency: Currency) {
    if (currency.id === selectedCurrency.id) return
    setSelectedCurrency(currency)
    if (props.updateCurrency != null) props.updateCurrency(currency)
  }

  useEffect(() => {
    //Updates the selected currency in session.
    dispatch(updateSelectedCurrency(selectedCurrency))
  }, [selectedCurrency])

  // useEffect(() => store.subscribe(() => {
  //   //When the session is updated triggers the callback to notify the "parent" component.
  //   const currency = _.clone(session.selectedCurrency)
  // }), [])



  //TABS

  // const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  // const tabsOpen = Boolean(anchorEl)

  function handleTabChange(event: any, value: string) {
    if (props.tabs == null) return

    //Check if the tab is a valid one.
    const tab = props.tabs.find(t => t.key === value)
    if (tab == null) return

    if (props.updateTab != null) props.updateTab(tab)

    //Updates the route.
    if (props.disableTabLink !== true) router.history.push(tab.key)
  }

  // const handleTabClick = (event: React.MouseEvent<HTMLElement>) => {
  //   setAnchorEl(event.currentTarget)
  // }

  // const handleTabsClose = () => {
  //   setAnchorEl(null)
  // }



  //ACTIONS

  const [anchorSecondaryActionsEl, setAnchorSecondaryActionsEl] = useState<null | HTMLElement>(null)
  const secondaryActionsAreOpen = Boolean(anchorSecondaryActionsEl)

  function renderActions(): React.ReactNode {
    const currencyActions = props.currency != null ? <CurrencySelector currencies={session.app.currencies} selectedCurrency={selectedCurrency} updateCurrencyCallback={updateCurrency}></CurrencySelector> : <></>
    const languageActions = props.language != null ? <LanguageSelector languages={session.app.languages} selectedLanguage={selectedLanguage} updateLanguageCallback={updateLanguage}></LanguageSelector> : <></>
    const headerActions = props.headerActions ?? <></>
    return <>{currencyActions}{languageActions}{headerActions}</>
  }

  const openSecondaryActions = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorSecondaryActionsEl(event.currentTarget)
  }

  const closeSecondaryActions = () => {
    setAnchorSecondaryActionsEl(null)
  }



  //UTILS

  function getTabs(): { key: string, label: string, value: any }[] {
    let tabs = []

    if (props.tabs == null) {
      tabs.push({ key: '1', label: t('page.tab.general'), value: 'main' })
    }

    if (props.tabs != null) {
      props.tabs!.forEach((tab, i) => (
        tabs.push({ key: i, label: tab.label ?? t('page.tab.' + tab.key), value: tab.key })
      ))
    }

    return tabs
  }



  //RENDER

  //If a default tab is provided and a selected tab is not, automatically redirect to the specific tab.
  if (props.currentRoute != null && props.defaultTab != null && props.selectedTab == null) {
    return <Redirect to={props.currentRoute + '/' + props.defaultTab} />
  }
  //If the selected tab is not a valid one redirect to an error page. 
  if (props.tabs != null && props.tabs?.find(t => t.key === props.selectedTab) == null) {
    return <Redirect to="/errors/404" />
  }

  //If the user doesn't have the permission redirects to an error page.
  if (userHasAccessToRoute(session.user, location.pathname) === false) {
    return <Redirect to={'/error/403'} />
  }

  if (props.isLoading === true) {
    return (
      <div className={classes.loading} >
        <Spinner />
      </div>
    )
  }

  let rootClass = ''
  if (props.layout === 'fullheight') rootClass = classes.rootFullHeight

  let contentClass = classes.contentDefault
  if (props.layout === 'fullscreen') contentClass = classes.contentFullScreen

  return (
    <div className={clsx(classes.root, rootClass, props.className)}>
      <PageMetatags title={props.title} parameters={props.parameters} />

      {props.layout !== 'fullscreen' && <PageHeader title={props.title} className={classes.header} previousRoute={props.previousRoute} actions={renderActions()} secondaryActions={props.headerSecondaryActions} />}

      <PageContent className={contentClass}>

        {(props.tabs != null || props.actions != null) &&
          <div className={classes.topBar}>

            <div className={classes.tabsContainer}>
              <Tabs value={props.selectedTab ?? 'main'} onChange={handleTabChange}>
                {getTabs().map((data) => (
                  data.value !== '' && <Tab key={data.key} label={data.label} value={data.value} />
                ))}
              </Tabs>
            </div>

            <div className={classes.actionsContainer}>
              {props.actions}
              {(props.secondaryActions != null && props.secondaryActions?.length !== 0) &&
                <>
                  <Button className={classes.secondaryActionsContainer} onClick={openSecondaryActions}>{t('common.more')}<ExpandMoreIcon /></Button>
                  <Menu anchorEl={anchorSecondaryActionsEl} open={secondaryActionsAreOpen} onClick={closeSecondaryActions} onClose={closeSecondaryActions}>
                    {props.secondaryActions?.map(e => e)}
                  </Menu>
                </>
              }
            </div>

            {/* <div className={classes.tabsContainerMobile}>
              <Menu anchorEl={anchorEl} open={tabsOpen} onClose={handleTabsClose}>
                {getTabs().map((data) => (
                  data.value !== '' && <MenuItem key={data.key} onClick={e => { handleTabClick(e); handleTabChange(e, data.value) }}>{data.label}</MenuItem>
                ))}
              </Menu>
            </div> */}
          </div>
        }

        {props.children}
      </PageContent>
    </div >
  )
}

export default Page
