import PageIcon from '@material-ui/icons/Description'
import CommonTextIcon from '@material-ui/icons/TextFields'
import ImportContactsIcon from '@material-ui/icons/ImportContacts'
import AnnouncementIcon from '@material-ui/icons/Announcement'
import BookIcon from '@material-ui/icons/Book'
import FastfoodIcon from '@material-ui/icons/Fastfood'
import UsersIcon from '@material-ui/icons/PeopleAlt'
import CodeIcon from '@material-ui/icons/Code'
import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFile'
import BusinessIcon from '@material-ui/icons/Business'
import CopyrightIcon from '@material-ui/icons/Copyright'
import StoreIcon from '@material-ui/icons/Store'
import PhotoLibraryIcon from '@material-ui/icons/PhotoLibrary'

const items = [
  {
    title: 'sidebar.title.website', pages: [
      { title: 'sidebar.item.pages', icon: PageIcon, href: '/website/pages' },
      { title: 'sidebar.item.dictionary', icon: CommonTextIcon, href: '/website/dictionary' },
      { title: 'sidebar.item.media-library', icon: PhotoLibraryIcon, href: '/website/media-library', },
    ]
  },

  {
    title: 'sidebar.title.blog', pages: [
      { title: 'sidebar.item.blog', icon: ImportContactsIcon, href: '/website/blog', },
      { title: 'sidebar.item.iosonovege', icon: BookIcon, href: '/website/iosonovege', },
      { title: 'sidebar.item.press-releases', icon: AnnouncementIcon, href: '/website/press-releases', },
    ]
  },

  {
    title: 'sidebar.title.data', pages: [
      { title: 'sidebar.item.products', icon: FastfoodIcon, href: '/shop/items', },
      { title: 'sidebar.item.companies', icon: BusinessIcon, href: '/general/companies', },
      { title: 'sidebar.item.brands', icon: CopyrightIcon, href: '/general/brands', },
      { title: 'sidebar.item.stores', icon: StoreIcon, href: '/general/stores', },
      { title: 'sidebar.item.documents', icon: InsertDriveFileIcon, href: '/general/documents', },
    ]
  },

  {
    title: 'sidebar.title.admin', pages: [
      { title: 'sidebar.item.users', icon: UsersIcon, href: '/admin/users' },
      { title: 'sidebar.item.dev', icon: CodeIcon, href: '/dev/overview' },
    ]
  },
]

export default items