import executeRequest, { RequestOutput } from 'app/api'

enum APIEndpoint {
  GetAllTaxonomies = 'api/taxonomies',
  GetTaxonomyById = 'api/taxonomies/getById',
  CreateTaxonomy = 'api/taxonomies/create',
  UpdateTaxonomy = 'api/taxonomies/update',
  DeleteTaxonomy = 'api/taxonomies/delete',
}


//Retrieves the languages in the CMS.
//Mainly used on application first loading, the response data is saved in the store for further use.

export type GetTaxonomiesRequestParams = {
  languageId: number
  taxonomyId?: number
}
export type GetTaxonomiesResponse = {
  taxonomies: {
    id: number
    keyword: string
    pageId: number
    pageTypeId: number
    parentId: number
    published: boolean
    translation: {
      name: string
    }[]
  }[]
}

enum GetTaxonomiesResponseError {
}

export async function getAllTaxonomies(params: GetTaxonomiesRequestParams, output?: RequestOutput<GetTaxonomiesResponse, GetTaxonomiesResponseError>) {
  executeRequest({
    endpoint: APIEndpoint.GetAllTaxonomies,
    params: params,
    output: output,
  })
}



export type GetTaxonomyRequestParams = {
  id: number
  languageId: number
}
export type GetTaxonomyResponse = {
  taxonomy: {
    id: number
    keyword: string
    pageId: number
    pageTypeId: number
    parentId: number
    mainImage: {
      id: number
      type: string
      folder: number
      filename: string
      basepath: string
      path: string
    }
    published: boolean
    translation: {
      name: string
    }[]
  }
}

enum GetTaxonomyResponseError {
}

export async function getTaxonomy(params: GetTaxonomyRequestParams, output?: RequestOutput<GetTaxonomyResponse, GetTaxonomyResponseError>) {
  executeRequest({
    endpoint: APIEndpoint.GetTaxonomyById,
    params: params,
    output: output,
  })
}



export type CreateTaxonomyRequestParams = {
  parentId: string | null
  pageType: number
}

type CreateTaxonomyResponse = {
  taxonomy: {
    id: number
  }
}

enum CreateTaxonomyResponseError {
}

export async function createTaxonomy(params: CreateTaxonomyRequestParams, output?: RequestOutput<CreateTaxonomyResponse, CreateTaxonomyResponseError>) {
  executeRequest({
    endpoint: APIEndpoint.CreateTaxonomy,
    params: params,
    output: output,
  })
}



export type UpdateTaxonomyRequestParams = {
  id: number
  keyword: string
  parentId: number | null
}

type UpdateTaxonomyResponse = {
}

enum UpdateTaxonomyResponseError {
}

export async function updateTaxonomy(params: UpdateTaxonomyRequestParams, output?: RequestOutput<UpdateTaxonomyResponse, UpdateTaxonomyResponseError>) {
  executeRequest({
    endpoint: APIEndpoint.UpdateTaxonomy,
    params: params,
    output: output,
  })
}



export type DeleteTaxonomyRequestParams = {
  id: number
}

type DeleteTaxonomyResponse = {
}

enum DeleteTaxonomyResponseError {
}

export async function deleteTaxonomy(params: DeleteTaxonomyRequestParams, output?: RequestOutput<DeleteTaxonomyResponse, DeleteTaxonomyResponseError>) {
  executeRequest({
    endpoint: APIEndpoint.DeleteTaxonomy,
    params: params,
    output: output,
  })
}
