import { lazy } from 'react'
import { RouteConfig } from 'react-router-config'

const routes: RouteConfig[] = [
	{ path: '/website/iosonovege/:tab?', exact: true, component: lazy(() => import('views/screens/blog/Articles')), },
	{ path: '/website/iosonovege/article/:id/:tab?', exact: true, component: lazy(() => import('views/screens/blog/ArticleDetails')) },
	{ path: '/website/iosonovege/category/:id/:tab?', exact: true, component: lazy(() => import('views/screens/blog/ArticlesCategoryDetails')) },
	{ path: '/website/press-releases/:tab?', exact: true, component: lazy(() => import('views/screens/blog/Articles')), },
	{ path: '/website/press-releases/article/:id/:tab?', exact: true, component: lazy(() => import('views/screens/blog/ArticleDetails')) },
	{ path: '/website/press-releases/category/:id/:tab?', exact: true, component: lazy(() => import('views/screens/blog/ArticlesCategoryDetails')) },

	{ path: '/general/documents', exact: true, component: lazy(() => import('views/screens/general/Documents')) },
	{ path: '/general/companies', exact: true, component: lazy(() => import('views/screens/general/Companies')) },
	{ path: '/general/companies/:id/:tab?', exact: true, component: lazy(() => import('views/screens/general/CompanyDetails')) },
	{ path: '/general/brands', exact: true, component: lazy(() => import('views/screens/general/Brands')) },
	{ path: '/general/brands/:id/:tab?', exact: true, component: lazy(() => import('views/screens/general/BrandDetails')) },
	{ path: '/general/stores', exact: true, component: lazy(() => import('views/screens/general/Stores')) },
	{ path: '/general/stores/:id/:tab?', exact: true, component: lazy(() => import('views/screens/general/StoreDetails')) },

	// { path: '/dev/pages/regions', exact: true, component: lazy(() => import('views/screens/dev/DevRegions')) },
	// { path: '/dev/pages/regions/:id/:tab?', exact: true, component: lazy(() => import('views/screens/dev/DevRegionDetails')) },
	// { path: '/dev/pages/provinces', exact: true, component: lazy(() => import('views/screens/dev/DevProvinces')) },
	// { path: '/dev/pages/provinces/:id/:tab?', exact: true, component: lazy(() => import('views/screens/dev/DevProvinceDetails')) },
	// { path: '/dev/pages/cities', exact: true, component: lazy(() => import('views/screens/dev/DevCities')) },
	// { path: '/dev/pages/cities/:id/:tab?', exact: true, component: lazy(() => import('views/screens/dev/DevCityDetails')) },
]

export default routes