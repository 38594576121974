import { Card, makeStyles } from '@material-ui/core';
import {
  addTagToPage, AddTagToPageRequestParams, getTags, GetTagsResponse,
  removeTagFromPage, RemoveTagFromPageRequestParams
} from 'app/api';
import { tagIsOfType } from 'app/entities/methods'
import { PageEntityDetailsRefreshableProps, CardField, CardContent, CardHeader } from 'components';
import { useLocalization } from 'components/methods'
import { Page, Tag } from 'app/entities/types';
import { toast } from 'app/utils'
import { TagValue } from 'app/values'
import difference from 'lodash/difference';
import { useEffect, useState } from 'react';
import { Color } from 'theme/style'

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100%',
  },
  tagSystem: {
    backgroundColor: Color.TagSystemBackground,
    color: Color.TagSystemText,
    '& svg': {
      color: Color.White,
      opacity: 0.6,
      '&:hover': {
        color: Color.White,
        opacity: 1,
      }
    }
  },
  tagThankYou: {
    backgroundColor: Color.TagThankYouBackground,
    color: Color.TagThankYouText
  },
  tagOnboarding: {
    backgroundColor: Color.TagOnboardingBackground,
    color: Color.TagOnboardingText
  },
  tagPolicy: {
    backgroundColor: Color.TagPolicyBackground,
    color: Color.TagPolicyText
  },
  tagShop: {
    backgroundColor: Color.TagShopBackground,
    color: Color.TagShopText
  },
}))

interface CardPageTagsProps extends PageEntityDetailsRefreshableProps {
  className?: string,
  object: Page,
  pageId: number
}

enum InputType {
  Tag = 'pageTags',
}

enum UpdateTagAction {
  None = '',
  Add = 'add',
  Remove = 'remove'
}

const Tags = ({ ...props }: CardPageTagsProps) => {
  const classes = useStyles()
  const { t } = useLocalization()

  const [isFetchingTags, setIsFetchingTags] = useState(false)
  const [tagsList, setTagsList] = useState<Tag[] | undefined>([])
  const [activeTags, setActiveTags] = useState<Tag[] | undefined>([])


  useEffect(() => {
    fetchTags()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    setActiveTags(props.object.tags)
  }, [props.object])

  const fetchTags = () => {
    const decode = (data: GetTagsResponse): Tag[] => {
      return data.tags
    }

    setIsFetchingTags(true)
    getTags({
      response(data) {
        const tags = decode(data)
        setTagsList(tags)
        setIsFetchingTags(false)
      },
      error(error, message) {
        toast.error(message)
        setIsFetchingTags(false)
      }
    })
  }

  const handleInputChange = (inputType: InputType, value: Tag[], event: any) => {
    if (activeTags == null) return

    const initialCount = activeTags.length
    const nextCount = value.length
    let tagsDiff: Tag[] = []
    let action: UpdateTagAction = UpdateTagAction.None

    if (initialCount > nextCount) {
      action = UpdateTagAction.Remove
      tagsDiff = difference(activeTags, value)
    }
    else if (initialCount < nextCount) {
      action = UpdateTagAction.Add
      tagsDiff = difference(value, activeTags)
    }

    if (tagsDiff.length > 1) return
    const tag = tagsDiff[0]

    switch (inputType) {
      case InputType.Tag: {
        setActiveTags(value)
        updateTags(tag, action)
        break
      }
    }
  }

  function updateTags(tag: Tag, action: UpdateTagAction) {
    if (props.object == null) return

    const addTag = (tag: Tag) => {
      const params: AddTagToPageRequestParams = {
        pageId: props.object.id,
        tagId: tag.id
      }

      addTagToPage(params, {
        response(data) {
          toast.success(t('alert.page-data.tags.update'))
        },
        error(error, message) {
          toast.error(message)
        }
      })
    }

    const removeTag = (tag: Tag) => {
      const params: RemoveTagFromPageRequestParams = {
        pageId: props.object.id,
        tagId: tag.id
      }

      removeTagFromPage(params, {
        response(data) {
          toast.success(t('alert.page-data.tags.update'))
        },
        error(error, message) {
          toast.error(message)
        }
      })
    }

    switch (action) {
      case UpdateTagAction.Add:
        addTag(tag)
        break

      case UpdateTagAction.Remove:
        removeTag(tag)
        break

      default:
        break
    }
  }

  return (
    <Card>
      <CardHeader title={t('tab.page-data.tags.title')} iconName="tags" isLoading={isFetchingTags} />
      <CardContent isLoading={isFetchingTags}>
        <CardField type={'tags'} name={InputType.Tag}
          placeholder={t('common.search')}
          items={tagsList ?? []} activeItems={activeTags}
          itemValueConstructor={(option) => option.name ?? ''}
          itemRenderClass={(option) => {
            if (tagIsOfType(option, TagValue.System)) return classes.tagSystem
            if (tagIsOfType(option, TagValue.ThankYou)) return classes.tagThankYou
            if (tagIsOfType(option, TagValue.Onboarding)) return classes.tagOnboarding
            if (tagIsOfType(option, TagValue.Shop)) return classes.tagShop
            if (tagIsOfType(option, TagValue.Policy)) return classes.tagPolicy
            return ''
          }}
          onUpdate={(e, value) => handleInputChange(InputType.Tag, value, e)}
        />
      </CardContent>
    </Card>
  )
}

export default Tags
